import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "button-store",
    target: "_blank",
    rel: "noopener noreferrer",
    href: $setup.getData[0]
  }, [
    _createElementVNode("img", {
      loading: "lazy",
      src: $setup.getData[1],
      alt: $setup.getData[2]
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}