import { onMounted, onBeforeUnmount, ref, reactive } from 'vue'

interface DeviceSize {
    tablet: boolean,
    desktop: boolean,
    tabletLate: boolean,
    mobile: boolean,
    mobileLate: boolean,
}

export default () => {
    const breakpoints = {
        tablet: 650,
        tabletLate: 768,
        desktop: 1200,
    }
    const size = reactive<DeviceSize>({
        mobile: false,
        mobileLate: false,
        tablet: false,
        tabletLate: false,
        desktop: false,
    })
    const type = ref<string | null>(null)

    onMounted(() => {
        getDeviceSize()
        getDeviceType()

        window.addEventListener('resize', getDeviceSize)
    })

    onBeforeUnmount(() => {
        window.removeEventListener('resize', getDeviceSize)
    })

    function getDeviceSize (): void {
        size.mobile = window.innerWidth < breakpoints.tablet
        size.mobileLate = window.innerWidth < breakpoints.tabletLate
        size.tablet =
            window.innerWidth >= breakpoints.tablet && window.innerWidth < breakpoints.desktop
        size.tabletLate =
            window.innerWidth >= breakpoints.tabletLate && window.innerWidth < breakpoints.desktop
        size.desktop = window.innerWidth >= breakpoints.desktop
    }

    function getDeviceType(): void {
        type.value = document.querySelector('meta[property="device"]')
            ? (document.querySelector('meta[property="device"]') as HTMLMetaElement).content
            : null
    }

    return {
        size,
        type,
    }
}
