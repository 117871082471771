import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["HeaderComponent"], { class: "page__header" }),
      _createVNode($setup["AboutComponent"], { class: "page__definition" }),
      _createVNode($setup["ContentComponent"], { class: "page__content" }),
      _createVNode($setup["BannerComponent"], { class: "page__banner" }),
      _createVNode($setup["FooterComponent"], { class: "page__footer" })
    ])
  ]))
}