
import { computed } from 'vue'
import deviceMixin from '@/mixins/device'

export default {
    setup(): unknown {
        const device = deviceMixin()

        const correctCards = computed(() => cards.value)

        const cards = computed(() => [
            {
                title: 'Предприниматели',
                description: 'Получайте доход от госзакупок при&nbsp;содействии сообщества',
                class: 'traders',
                image: `/images/traders--${device.size.mobile ? 'mobile' : 'desktop'}.png`,
                background: null,
            },
            {
                title: 'Банковские агенты',
                description: 'Находите новых клиентов и решения для них',
                class: 'agents',
                image: `/images/agents--${device.size.mobile ? 'mobile' : 'desktop'}.png`,
                background: null,
            },
            {
                title: 'Тендерные специалисты',
                description: 'Общайтесь с коллегами и&nbsp;повышайте свой доход',
                class: 'tenders',
                image: `/images/tenders.png`,
                background: '/images/tenders-background.png',
            },
            {
                title: 'Компании',
                description: 'Повышайте узнаваемость и&nbsp;общайтесь со своей аудиторией.',
                class: 'companies',
                image: '',
                background: '/images/companies-background.png',
            },
            {
                title: 'Заказчики',
                description: 'Получайте ответы на сложные вопросы и общайтесь с исполнителями',
                class: 'customers',
                image: '/images/customers.png',
                background: '/images/customers-background.png',
            },
        ])

        return {
            cards,
            correctCards,
            device
        }
    }
}

