import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "store-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.actions, (action) => {
      return (_openBlock(), _createBlock($setup["ButtonStore"], {
        class: "store-actions__button",
        type: action
      }, null, 8, ["type"]))
    }), 256))
  ]))
}